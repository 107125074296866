
.table-contents-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-x: auto;
	font-size: 14px;
	border-radius: 5px;
	& > * {
		flex-shrink: 0;
	}
	  
}

[role="table"] {
	line-height: 1.2;
	border-collapse: collapse;

	[role="row"] {
		box-sizing: border-box;
	}

	[role="cell"], [role="columnheader"] {
		padding: 1rem .5rem;
		overflow-wrap: break-word;
		position: relative;
		&.isSticky {
			position: sticky;
			right: 0;
			left: 0;
			background: #0A1118;
			z-index: 1;
		}

		&.isResizing {
			box-shadow: inset -2px 0 0 0 royalblue;
			cursor: col-resize;

			& ~ [role="cell"], & ~ [role="columnheader"] {
				cursor: col-resize;
			}
		}

		&.isRowLoading {
			pointer-events: none;
			color: #aaa;
		}
	}

	
	[role="columnheader"] {

		&.canSort {
			cursor: pointer;
			user-select: none;

			&:hover {
				background:#141b27
			}
		}

		&:not(.isSorted):not(:hover) {
			.sorting-icon {
				opacity: 0;
			}
		}

		.sorting-icon {
			display: inline;
			opacity: 0.25;
			margin-inline: 3px;
		}
	
	}

	[data-table-role="thead"] {
		text-align: left;
		background: #1C2738;
		font-weight: 600;
	}


	[data-table-role="tbody"] {


		background: #0A1118;
		[role="row"] {
			&.isSelected {
				background: rgba(royalblue, 0.03);
			}
		}

		[role="row"]:hover {
			background: #141b27;
			[role="cell"]:first-child {
				box-shadow: inset 2px 0 0 0 royalblue;
			}
		}
		td{
			align-self: stretch;
			width: 100%;
		}
	}

	

	tr:not(:first-child) {
		border-top: 1px solid #1A2333;
		border-collapse: collapse;
	}
	th {
		border-left: 1px solid #1A2333;
		border-collapse: collapse;
	}
	td {
		border-left: 1px solid #1A2333;
		border-right: 1px solid #1A2333;
		border-bottom: 1px solid #1A2333;
		border-collapse: collapse;
	}

	.resizer {
	
		position: absolute;
		top: 0;
		right: 0;
		transform: translateX(50%);
		height: 100%;
		width: 20px;
		cursor: col-resize;
		user-select: none;
		touch-action: none;
		padding: 1px;

		svg {
			transform: rotate(90deg);
			stroke: #676767;
			&:hover {
				stroke: #fff
			}
		}
	}

	.resizer.isResizing {
		opacity: 1;

		svg {
			display: none;
		}
	}
	
	@media (hover: hover) {
		.resizer {
			opacity: 0;
		}
	
		*:hover > .resizer {
			opacity: 1;
		}
	}
}